<template>
<div class='page-container'>
<div class='content' id="response" >
<div v-if="!ready" class='loading'><i class="fas fa-spinner fa-pulse"></i></div>
</div>
</div>
</template>

<script>
export default {
  name: 'VideoChat',
  components: {
  },
  props: {
    user: Object,
    toggleShowChat: Function,
    setSelectedUser: Function
  },
  data() {
    return {
      user_uuid: '',
      domain: 'jitsi01.b-rex.de',
      room_name: 'munis-46f17e8a-847c-11ea-bc8d-0242ac140002',
      user_first_name : '',
      user_last_name: '',
      user_email: '',
      ready: false
    };
  },
  mounted() {
    this.changeBackBtnText();
    this.fetchJitsi();
    this.user_uuid = this.$route.params.user_uuid;
    this.user_first_name = this.user.first_name;
    this.user_last_name = this.user.last_name;
    this.getVideoChat(this.user_uuid);
  },
  methods: {
    changeBackBtnText() {
      document.getElementById('backbtn').innerHTML = '<i class="fas fa-angle-left mr-2"></i> Videochat beenden';
    },
    fetchJitsi() {
      let jitsi = document.createElement('script');
      jitsi.setAttribute('src', 'https://meet.jit.si/external_api.js');
      document.head.appendChild(jitsi);
      top.document.title='Video-Chatfenster';
      document.getElementById('backbtn').onclick = function() { window.close(); };
      document.getElementById('customerlogo').onclick = function() { window.close(); };
      document.getElementById('searchfield').remove();
    },
    getVideoChat(id) {
      var domain = '';
      var room_name = '';
      domain = localStorage.getItem('video-domain');
      room_name = localStorage.getItem('room-name');
      if (domain && room_name) {
        this.prepareRoom(domain, room_name);
      } else {
        this.$http.get('/direct-video-chat', {
          params: {
            user_uuid: id
          }
        })
          .then(resp => {
            this.domain = resp.data.domain;
            this.room_name = resp.data.room_name;
            localStorage.setItem('video-domain', this.domain);
            localStorage.setItem('room-name', this.domain);
            this.prepareRoom(this.domain, this.room_name);
          }).catch(err => {
            console.log(err);
          });
      }
    },

    prepareRoom(domain, room_name) {
      try{
        var options = {
          roomName: room_name,
          width: '100%',
          height: '100%',
          noSSL: false,
          parentNode: document.getElementById('response'),
          userInfo: {
            displayName: `${this.user.first_name} ${this.user.last_name}`
          }
        };
        var jitsi = new window.JitsiMeetExternalAPI(domain, options);
        // fix for not setting the display name:
        this.ready = true;
        jitsi.executeCommand('displayName', `${this.user.first_name} ${this.user.last_name}`);//this.user_first_name + ' ' + this.user_last_name );
        jitsi.addEventListener('readyToClose', () => {
          localStorage.removeItem('video-domain');
          localStorage.removeItem('room-name');
          if(localStorage.getItem('user-roles').includes('exhibitor') || localStorage.getItem('user-roles').includes('organizer')) {
            this.$router.go(-1);
          } else {
            window.close(); //If user is pressing "auflegen", close window
            this.setSelectedUser(this.user_uuid);
            this.toggleShowChat();
            this.$router.go(-1);
          }
        });
      } catch {
        setTimeout(function () { 
          this.prepareRoom(domain, room_name);
        }.bind(this), 500); 
      }
    }
  }
};
</script>

<style scoped>
  body, html {
    height: 100%;
  }

  .content {
    margin: 0px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 100%;
    height: calc(100vh - 120px);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
  }

  .page-container{
    margin: 0px;
    width: 100%;
    height: calc(100vh - 124px);
    padding-left: 0px !important;
    padding-right: 0px !important;
    position: relative;
  }

  .loading {
    position: absolute;
    height: 0;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    font-size: 100px;
  }
</style>
